// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/accounts/icon-login-button.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".signup-result__description--paragraph{margin-bottom:24px}.signup-result__button{margin-top:24px}.signup-result__button:before{content:\"\";display:inline-block;width:16px;height:20px;margin-right:3px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:16px 20px;background-repeat:no-repeat;background-position:50%;vertical-align:-4px}", ""]);
// Exports
module.exports = exports;
