<template>
  <div class="wrapper">
    <div class="singup-result inner-wrapper">
      <div class="accounts-form">
        <h1
          class="accounts-form__small-bi-header cursor"
          @click="$router.push('/introduction/main')"
        >
          Qpick
        </h1>
        <h1 class="accounts-form__header">회원가입</h1>
        <div class="accounts-form__description">
          회원가입이 완료되었습니다.<br />
          지금 즉시 나만의 테마를 만들어보세요.
        </div>
        <p class="accounts-infobox">
          <span>아이디</span>
          <span>{{ email }}</span>
        </p>
        <button
          class="signup-result__button accounts-form__submit"
          @click="login"
        >
          로그인하기
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/accounts/basis.scss";

export default {
  data() {
    return {
      email: ""
    };
  },
  methods: {
    login() {
      this.$router.push("/accounts/login");
    }
  },
  mounted() {
    this.email = this.$router.currentRoute.query.email;
  }
};
</script>

<style>
.signup-result__description--paragraph {
  margin-bottom: 24px;
}
.signup-result__button {
  margin-top: 24px;
}
.signup-result__button::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 20px;
  margin-right: 3px;
  background-image: url("~@/assets/images/accounts/icon-login-button.svg");
  background-size: 16px 20px;
  background-repeat: no-repeat;
  background-position: center center;
  vertical-align: -4px;
}
</style>
