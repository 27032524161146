var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "singup-result inner-wrapper"
  }, [_c('div', {
    staticClass: "accounts-form"
  }, [_c('h1', {
    staticClass: "accounts-form__small-bi-header cursor",
    on: {
      "click": function click($event) {
        return _vm.$router.push('/introduction/main');
      }
    }
  }, [_vm._v(" Qpick ")]), _c('h1', {
    staticClass: "accounts-form__header"
  }, [_vm._v("회원가입")]), _vm._m(0), _c('p', {
    staticClass: "accounts-infobox"
  }, [_c('span', [_vm._v("아이디")]), _c('span', [_vm._v(_vm._s(_vm.email))])]), _c('button', {
    staticClass: "signup-result__button accounts-form__submit",
    on: {
      "click": _vm.login
    }
  }, [_vm._v(" 로그인하기 ")])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accounts-form__description"
  }, [_vm._v(" 회원가입이 완료되었습니다."), _c('br'), _vm._v(" 지금 즉시 나만의 테마를 만들어보세요. ")]);
}]

export { render, staticRenderFns }